export const solo: { year: number; name: string; location: string }[] = [
  {
    year: 2022,
    name: 'SUB SUS',
    location: 'Galeria Th.Pallady, Iasi',
  },
  {
    year: 2021,
    name: 'Simon’s People',
    location: 'Galeria N.Tonitza, Iasi',
  },
];

export const collective: Record<number, { name: string; location: string }[]> =
  {
    2022: [
      {
        name: 'Flower Power',
        location:
          'Galeria Alchimia, Facultatea de Inginerie Chimică și Protecția Mediului ”Cristofor Simionescu”, Iasi',
      },
      {
        name: 'Atelier 35',
        location: 'Galeria Victoria & Galeria La Gard, Iasi',
      },
      {
        name: 'Pavot Art Gallery',
        location: 'JW Marriott Grand Hotel, Bucharest',
      },
    ],
    2021: [
      {
        name: 'Mecena Art Gallery',
        location: 'Bucuresti',
      },
      {
        name: 'Atelier 35',
        location: 'Galeria La Gard, Iasi',
      },
      {
        name: 'Calea Suferintei',
        location: 'Muzeul Municipal Regina Maria, Iasi',
      },
      {
        name: 'Mesmerizing colors of HAPPINESS',
        location: 'Palace of Culture, Iasi & Muliebrity, Iasi',
      },
      {
        name: 'Din inalt spre inalt',
        location: 'HAISUS-CREATIVE CLOUD, Iasi',
      },
      {
        name: 'Stiinta si arta-antientropic',
        location: 'Sala Pasilor Pierduti, Al.I.Cuza university, Iasi',
      },
      {
        name: 'Bienala de pictura "Alexandru Ciucurencu"',
        location: 'Muzeul de Arta, Tulcea',
      },
      {
        name: 'ARTIS',
        location: 'Muzeul Unirii ,Iasi',
      },
      {
        name: 'Bienala nationala de arta plastica "Lascar Vorel"',
        location: 'Complexul Muzeal National Neamt, Piatra Neamt',
      },
    ],
    2020: [
      {
        name: 'Abstract Expression',
        location: 'Online',
      },
    ],
    2019: [
      {
        name: 'Teritorii dedicate sufletului',
        location: 'Muzeul Unirii, Iasi',
      },
      {
        name: 'The future is now',
        location: 'Sala Pasilor Pierduti, Al.I.cuza University, Iasi',
      },
    ],
    2018: [
      {
        name: 'TramArt Studio',
        location: 'Iasi',
      },
      {
        name: 'Visiting Art History',
        location: 'Sala Pasilor Pierduti, Al.I.cuza University, Iasi',
      },
      {
        name: 'Urme ale sufletului',
        location: 'Muzeul Unirii, Iasi',
      },
      {
        name: 'Enneagram',
        location: 'Sala Pasilor Pierduti, Al.I.cuza University, Iasi',
      },
    ],
    2017: [
      {
        name: 'Expresii',
        location: 'Galeria Tonitza, Iasi',
      },
      {
        name: 'Visiting Art History',
        location: 'Sala Pasilor Pierduti, Al.I.cuza University, Iasi',
      },
    ],
  };

export const orientations: {
  year: number;
  orientation: 'right' | 'left';
  isTop?: boolean;
  isBottom?: boolean;
}[] = [
  {
    year: 2022,
    orientation: 'right',
    isTop: true,
  },
  {
    year: 2021,
    orientation: 'left',
  },
  {
    year: 2020,
    orientation: 'right',
  },
  {
    year: 2019,
    orientation: 'left',
  },
  {
    year: 2018,
    orientation: 'right',
  },
  {
    year: 2017,
    orientation: 'left',
    isBottom: true,
  },
];
