export const routes = {
  homepage: {
    path: '/',
    landing: {
      path: 'landing',
    },
    about: {
      path: 'about',
    },
    artworks: {
      path: 'collections',
    },
    contact: {
      path: 'contact',
    },
  },
  gallery: {
    causality: {
      path: 'causality',
    },
    motamot: {
      path: 'motamot',
    },
    tataraces: {
      path: 'tataraces',
    },
    consigliere: {
      path: 'consigliere',
    },
  },
};
